<template>
    <div class="container" ref="wrapper">
        <van-sticky>
            <van-nav-bar title="快递详情" left-arrow @click-left="$router.back()" />
        </van-sticky>

        <van-row>
            <van-col offset="2" span="22">
                <h3>顺丰单号:{{transportID}}</h3>
            </van-col>
        </van-row>

        <van-steps direction="vertical" :active="0">
            <van-step v-for="(item,index) in routes" :key="index">
                <h3>{{item.acceptAddress}}</h3>
                <p>{{item.acceptTime}}</p>
                <p>{{item.remark}}</p>
            </van-step>
        </van-steps>


    </div>
</template>
    
<script>
import { GetSFExpress } from "@/api/A3Express";
//const key = "zn-history";
export default {
    name: "answerList",
    data() {
        return {
            transportID:'',
            para:{
                listItem:[]
            },
            routes:[],
        };

    },
    components: {},
    
    created() {
        this.init();
    },
    mounted() { },

    methods: {
        async init() {

            this.transportID=this.$route.query.para;
            this.para.listItem=[this.transportID];
            let result=  await GetSFExpress(this.para);
            if(result.data.success && result.data.data.success)
            {
                this.routes=result.data.data.msgData.routeResps[0].routes.reverse();
            }
        },
    },
};
</script>
    
<style lang="less" scoped>
.w100 {
    /deep/ img {
        width: 100%;
        max-height: 80px;
    }
}

.zntitle {
    height: 80px;
}

.container {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
}

.detail {
    padding: 46px 10px 44px;

    .title {
        font-size: 18px;
        line-height: 2;
    }

    .zan {
        text-align: center;
        padding: 10px 0;

        .active {
            border-color: red;
            color: red;
        }
    }

    .author {
        padding: 10px 0;
        display: flex;
        position: sticky;
        background-color: #fff;
        top: 46px;
        z-index: 2;

        .text {
            flex: 1;
            padding-left: 10px;
            line-height: 1.5;

            .name {
                font-size: 14px;
                margin: 0;
            }

            .time {
                margin: 0;
                font-size: 12px;
                color: #999;
            }
        }
    }

    .content {
        padding: 20px 0;
        overflow: hidden;
        white-space: pre-wrap;
        word-break: break-all;

        /deep/ img {
            max-width: 100%;
            background: #f9f9f9;
        }

        /deep/ code {
            white-space: pre-wrap;
        }
    }
}
</style>
    